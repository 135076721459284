// extracted by mini-css-extract-plugin
export var blogsContainer = "index-module--blogsContainer--39485";
export var collectionContainer = "index-module--collectionContainer--bb011";
export var contentTitle = "index-module--contentTitle--e2977";
export var ctaCustomButton = "index-module--ctaCustomButton--12636";
export var gold = "index-module--gold--2e68a";
export var highlightContainer = "index-module--highlightContainer--bc607";
export var linkContainers = "index-module--linkContainers--e82e1";
export var messageContainer = "index-module--messageContainer--aea24";
export var newArrivalsContainer = "index-module--newArrivalsContainer--ff722";
export var promotionContainer = "index-module--promotionContainer--66925";
export var root = "index-module--root--7d083";
export var socialContainer = "index-module--socialContainer--cf687";
export var socialContentGrid = "index-module--socialContentGrid--397c3";
export var sustainableContainer = "index-module--sustainableContainer--cc538";